body {
	background:#fff;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

/* Added new button class named btn-cust-col to change background color of button along with padding */
.btn-cust-col {
  background-color: #036667 !important;
  padding: 10px 22px;
  margin-top: 1rem;
}

.cust-outline:focus{
 border-color: #036667 !important;
 box-shadow: inset 0 0 0 1px #036667 !important;
 border-bottom: 1px solid #036667 !important;
 }
 .cust-outline:focus + label {
  color: #036667 !important;
}
 /* .cust-outline.label{
  color: #036667 !important;
} */

 
.px-2 {
  padding-right: 30px !important;
}

.px-1 {
  padding-left: 10px !important;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

.sectionPage::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: url('https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg');
  background-position: center center;
  background-size: cover;
  filter: opacity(.1);
}

.mdb-datatable-entries .bs-select select{
  margin-left: 0.5rem;
  margin-top: -0.3rem;
  float: right;
  width: 4rem !important;
}
.progressStyle{
  height:1rem;
  margin:0.2rem;
  width: 80%;
  float: left;
}
