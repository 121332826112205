* {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }
  body {
    position: relative;
    /* overflow: visible; */
    /*font-family: monospace;*/
    min-width: 100%;
    min-height: 100%;
  }
  
  a,
  a:link,
  a:hover,
  a:visited,
  a:focus {
    color: #0d0e0d;
  }

  #tasks > li {
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    list-style-type: none;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    margin-left: -10px;
  }
  .layout {
    /*overflow: visible;
    position: absolute;
      */
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; 
    }
    .layout > .column {
      /* overflow: visible; */
      position: absolute;
      /*min-height: 100%;
       padding: 1em; */
    }
    .layout > .left {
      /* margin-left: 0px; */
      left: 0;
      width: 23%;
      border-right: 1px solid black;
      min-height: 500px;
    }
    .layout > .right {
      right: 0;
      width: 77%;
      /* border-left: 1px solid black; */
      min-height: 500px;
    }
    .form-group {
      /* overflow: visible; */
      padding: 1em 0;
      margin-bottom: 1em;
    }
    
    .form-group-label {
      float: left;
      /* width: 30%; */
      /* padding: 0 1em 0 0; */
    }
    .control-label,
    .form-control {
      float: left;
    }
    
    .control-label,
    .form-control input{
      vertical-align: left;
      line-height: 2em;
    }
    
    .control-label {
      text-align: left;
      width: 33%;
      padding: 0 1em 0 0;
    }
    .form-control {
      width: 100%;
      padding: 0 0 0 1em;
    }
    
    .form-control input[type="text"]{
      width: 100%;
    }
    
    .form-btn {
      float:right;
      padding: 1 1 1 1em;
      margin-top:1em;
      margin-bottom: 1em;
      color: white;
      background-color:#053fb4;
    }
    p.blocktext {
      margin-left: auto;
      margin-right: auto;
      width: 20rem;
      padding: 1rem;
  }