div container {
    margin: 0; 
    padding: 0
}
.custom-bg-color {
  background-color: #036667;
}
.table {
    border: 1px solid #b0cbef;
    border-width: 1px 0px 0px 1px;
    font-size: 11pt;
    font-family: Calibri;
    font-weight: 100;
    border-spacing: 0px;
    border-collapse: collapse;
  }
  
  .table th {
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
    height: 17px;
  }
  
  .table td {
    border: 0px;
    background-color: white;
    padding: 0px 4px 0px 2px;
    border: 1px solid #d0d7e5;
    border-width: 0px 1px 1px 0px;
  }
  
  .table td b {
    border: 0px;
    background-color: white;
    font-weight: bold;
  }
  
  .table td.heading {
    background-color: #8fbbf8;
    text-align: center;
    border: 1px solid #0754a1;
    border-width: 0px 1px 1px 0px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .table th.heading {
    background-repeat: none;
  }